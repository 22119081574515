import { Component } from 'react';
class Functions extends Component {
  onChange(event, object) {
    let nam = event.target.name;
    let val = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    object.setState({ [nam]: val });
    // console.log(val)
  }

  /**
 * 
 * @param {String} CatName pass category name/location type
 * @returns {String} get Image path and set on Image Tag source
 */
  onSetCategoryImages = (CatName = "Other", congestion = "BLUE") => {
    CatName = CatName.toUpperCase();
    congestion = congestion?.toUpperCase() || ""

    let source = process.env.PUBLIC_URL + `../assets/icon/OTHER.png`;
    if (CatName === "TOURIST PLACES") {
      source = process.env.PUBLIC_URL + `../assets/icon/TOURIST_PLACES.png`;
    } else if (CatName === "MONUMENTS") {
      source = process.env.PUBLIC_URL + `../assets/icon/MONUMENTS.png`;
    } else if (CatName === "MUSEUM") {
      source = process.env.PUBLIC_URL + `../assets/icon/MUSEUM.png`;
    } else if (CatName === "STORES") {
      source = process.env.PUBLIC_URL + `../assets/icon/STORES.png`;
    } else if (CatName === "BBQ AREA") {
      source = process.env.PUBLIC_URL + `../assets/icon/BBQ_AREA.png`;
    } else if (CatName === "DOGS AREA") {
      source = process.env.PUBLIC_URL + `../assets/icon/DOGS_AREA.png`;
    } else if (CatName === "SHOP") {
      source = process.env.PUBLIC_URL + `../assets/icon/SHOP.png`;
    } else if (CatName === "TOILET") {
      source = process.env.PUBLIC_URL + `../assets/icon/TOILET.png`;
    } else if (CatName === "PARKING") {

      if (congestion === "GREEN") {
        source = process.env.PUBLIC_URL + `../assets/icon/PARKING_G.png`;
      }
      else if (congestion === "AMBER") {
        source = process.env.PUBLIC_URL + `../assets/icon/PARKING_A.png`;
      }
      else if (congestion === "RED") {
        source = process.env.PUBLIC_URL + `../assets/icon/PARKING_R.png`;
      }
      else {
        source = process.env.PUBLIC_URL + `../assets/icon/PARKING.png`;
      }
    } else if (CatName === "BEACH") {
      source = process.env.PUBLIC_URL + `../assets/icon/BEACH.png`;
    } else if (CatName === "GROCERY") {
      source = process.env.PUBLIC_URL + `../assets/icon/GROCERY.png`;
    } else if (CatName === "RESTAURANTS") {
      source = process.env.PUBLIC_URL + `../assets/icon/RESTAURANTS.png`;
    } else if (CatName === "BANK") {
      source = process.env.PUBLIC_URL + `../assets/icon/BANK.png`;
    } else if (CatName === "FIRST AID") {
      source = process.env.PUBLIC_URL + `../assets/icon/FIRST_AID.png`;
    } else if (CatName === "TIP") {
      source = process.env.PUBLIC_URL + `../assets/icon/TIP.png`;
    } else if (CatName === "SAFE SPACE") {
      source = process.env.PUBLIC_URL + `../assets/icon/SAFE_SPACE.png`;
    } else if (CatName === "OTHER" || CatName === "OTHERS") {
      source = process.env.PUBLIC_URL + `../assets/icon/OTHER.png`;
    }
    return source;
  }

  getColourCode = (CongestionFlag = "lightgrey") => {

    if (!CongestionFlag) return 'lightgrey';

    if (CongestionFlag.toLowerCase() === "green") {
      return "rgba(0, 200, 0, 0.5)";
    }
    else if (CongestionFlag.toLowerCase() === "orange") {
      return "rgba(245, 171, 53, 0.5)";
    }
    else if (CongestionFlag.toLowerCase() === "red") {
      return "rgba(200, 0, 0, 0.5)";
    }
    else if (CongestionFlag.toLowerCase() === "black") {
      return "rgba(0, 0, 0, 0.5)";
    }
    else {
      return "lightgrey";
    }
  }

  getSafetyOptions = async (optionId = null) => {
    let options = this.options;

    if (this.options.length <= 0) {
      options = await this.fetchSafetyOptions().safetyOptions;
      options = options?.safetyOptions || [];
      console.log("options: ", options);
    }

    if (optionId) {
      // console.log("id: ", Object.values(options.safetyOptions).find((opt, id) => { console.log(opt.ID, optionId, opt.ID == optionId); return opt.ID == optionId}));
      return Object.values(options).find((opt, id) => opt.ID == id) || {}
    }
    else {
      return options;
    }
  }

  getSafetyOptionByName = async (name = null) => {
    let options = this.options;

    if (this.options.length <= 0) {
      options = await this.fetchSafetyOptions();
      options = options?.safetyOptions || [];
      console.log("options: ", options);

    }

    if (name) {
      console.log("id: ", Object.values(options).find((opt, id) => { console.log(opt.Name, name, opt.Name == name); return opt.Name == name }));
      return Object.values(options).find((opt, id) => opt.Name == name) || {}
    }
    else {
      return options;
    }
  }

  iconSourceById = (id) => {
    // console.log(id, this.getSafetyOptions(id));
    let icon = this.getSafetyOptions(id).then((res) => { return res?.Name?.toLowerCase()?.replace(' ', '') }) || "safehavens";
    let selected = "green"
    switch (`${icon}_${selected}`) {
      case 'firstaid_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/firstaid_green.png`
      case 'cctv_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/cctv_green.png`
      case 'openshop_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/openshop_green.png`
      case 'guardians_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/guardians_green.png`
      case 'safeheavens_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_green.png`
      case 'safespace_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_green.png`
      case 'people_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/people_green.png`
      case 'streetlight_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/streetlight_green.png`
      default:
        return null
    }
  }

  iconSource = (icon, selected = false) => {
    icon = icon.toLowerCase().replace(' ', '');
    console.log(icon)
    selected = selected ? "white" : "green"
    switch (`${icon}_${selected}`) {
      case 'firstaid_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/firstaid_green.png`
      case 'cctv_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/cctv_white.png`
      case 'cctv_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/cctv_green.png`
      case 'openshop_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/openshop_white.png`
      case 'openshop_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/openshop_green.png`
      case 'guardians_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/guardians_white.png`
      case 'guardians_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/guardians_green.png`
      case 'guardian_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/guardians_white.png`
      case 'guardian_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/guardians_green.png`
      case 'safeheavens_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_white.png`
      case 'safeheavens_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_green.png`
      case 'safeheaven_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_white.png`
      case 'safeheaven_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_green.png`
      case 'safespace_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_white.png`
      case 'safespace_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/safehavens_green.png`
      case 'people_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/people_white.png`
      case 'people_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/people_green.png`
      case 'streetlight_white':
        return process.env.PUBLIC_URL + `../assets/safety_options/streetlight_white.png`
      case 'streetlight_green':
        return process.env.PUBLIC_URL + `../assets/safety_options/streetlight_green.png`
      default:
        return process.env.PUBLIC_URL + `../assets/safety_options/cctv_white.png`
    }
  }

}


const AllFunction = new Functions();
export default AllFunction;