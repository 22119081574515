import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";
import Autocomplete from "react-google-autocomplete";

// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';

// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'




class RegisterEvent extends Component {
    constructor(props) {
        super(props);
        let enableInput = false

        const { eventid } = props.params;
       
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            Locations: [],
            country_code: '+91',
            ccode: "+91",
            email: "",
            mobileNo: "",
            remark: "",
            LoactionName: "",
            address: "",
            firstName: "",
            lastName: "",
            country: "",
            state: "",
            city: "",
            QRCodeValue: "",
            selectedLocation:"",
            postal_code: "",
            area: "",
            street_address: "",
            addressComponent: null,
            eventId:eventid!=null ? eventid :"65c5dfabce585f6d12c398d2",
            userId:"",
            defaultAddress: "",
            enableInput: enableInput,
            IsRegister:enableInput
        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

        await this._getUserCred();

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    _getUserCred = async () => {


        await this.onGetLocationsList();

        // const { state, signOut } = this.context;

    }


    onGetLocationsList = async () => {
        await this.setState({ loading: true })

        const { token, user, searchText } = this.state;

        let state = user
        let urlPath = "DynamicPage/entry-locations/"

        // // console.log("List Role =>", Constants.url + urlPath)

        // // // console.log("TOKEN ",token)
        // return 

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    })
                  
                    if (LocationsList.length > 0) {
                        this.state.Locations=LocationsList;
                        // setSelectedLocation(LocationsList[0])
                        this.setState({ selectedLocation: LocationsList[0].place_id, defaultAddress: this.getFormattedAddressComponentData(LocationsList[0]?.addressComponent || null), refreshInput: true })
                    }
                    setTimeout(() => {
                        this.setState({ Locations: LocationsList, refreshInput: false })
                    }, 100)
                    //   await this.setState({ RoleList, loading: true })

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                } else {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }



    numberCheckAPI = async () => {

        // this.setState({ loading : true })
        this.setState({ info: { type: "info", msg: "Looking for user, wait..." }, errors: {}, isNumberVerified: true, loading: false });
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { ccode, mobileNo,eventId } = this.state;
        const Body = JSON.stringify({
            'countryCode': ccode,
            eventId:eventId,
            "mobileNo": mobileNo.trim(),
            'client': Constants.AUTH_APP_NAME,
            'client_secret': Constants.AUTH_SECRET,
            'eventId':eventId
        })

        let urlPath = "auth/check-mobile-eventregistered";
        //let urlPath = "check-mobile-registered";

        // console.log(Constants.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                this.state.IsRegister=false;
                this.state.enableInput=true;
                this.state.firstName="";
                this.state.lastName="";
                this.state.userId="";
                 console.log(responseData);
                 let errors = {}
                if(responseData.response!=null){
                    if(responseData.response.user!=null){
                        errors.mobileNo = "User already registered.";
                    var user=responseData.response.user
                    this.state.firstName=user.firstName;
                    this.state.lastName=user.lastName;
                    this.state.userId=user._id;
                    this.state.enableInput=false;
                    
                    }
                    if(responseData.response.event!=null){
                        errors.mobileNo = "User already registered in event.";
                        var event=responseData.response.event
                        this.state.eventId=event._id;
                        this.state.QRCodeValue=this.state.userId+
                        "_"+event._id
                        this.state.IsRegister=true;
                    }
                }
                if (responseData.status && responseData.auth) {
                  
                   
                    this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                   
                    this.setState({ info: { type: "success", msg: "User is valid" }, errors: {}, isNumberVerified: true, loading: false });


                } else if (typeof responseData.error === "object") {
                    alert(
                        'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading : false })
                    let errors = {}

                    errors.mobileNo = "Something went wrong.";

                    this.setState({ errors: errors, isNumberVerified: false, loading: false });
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )

                    let errors = {}

                    errors.mobileNo = "Something went wrong.";

                    this.setState({ errors: errors, isNumberVerified: false, loading: false });
                }

            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                let errors = {}

                errors.mobileNo = "Something went wrong.";

                this.setState({ errors: errors, isNumberVerified: false, loading: false });

            }).finally(final => {

            });
    }



    checkMobileNumberIsRegistrered = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue) && inputValue.length == 10) {

            this.numberCheckAPI()

        } else {
            this.setState({ isNumberVerified: false })
        }

    };
    handleNumberChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue)) {
            // Valid input: 10 digits without spaces
            // Handle the input or set the state accordingly
            // For example:
            // console.log("N",inputValue,inputValue.length)
            this.setState({ mobileNo: inputValue });
        } else {
            // // // console.log("E",inputValue)
            // Invalid input
            // You can show an error message or prevent further action
        }
    };


    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        }

        if (this.state.lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        }

        if (this.state.assignRole?.trim() === "") {
            formIsValid = false;
            errors.assignRole = "Please assign role";
        }

        if (this.state.ccode?.trim() === "") {
            formIsValid = false;
            errors.ccode = "Please enter country code";
        }

        if (this.state.email?.trim() === "") {
            formIsValid = false;
            errors.email = "Please enter email address";
        }

        if (this.state.mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }




        this.setState({ errors: errors });

        return formIsValid;
    }


    onUserInvite = async (e) => {
        e.preventDefault()

        //this.setState({ loading: true })

        // if (!this.handleValidation()) {
        //     return false
        // }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { email, ccode, mobileNo, assignRole, firstName, lastName, token, selectedLocation ,city,remark,userId,eventId,street_address} = this.state;
        const Body = JSON.stringify({
            mobileNo: mobileNo,
            // countryCode: "+" + ccode,
            firstName: firstName,
            lastName: lastName,
            email: email,
            // sangathanId: selectedSangathan._id,
            selectedLocation:selectedLocation,
            role: assignRole,
            city:city || '',
            remark:remark,
            userId:userId,
            eventId:eventId,
            'street_address': `${street_address}`,
        });

        let urlPath = "event/registerEvent";

        console.log(Constants.VUF_APP_API + urlPath, token, Body)
        // return

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                if (responseData.status && responseData.response ) {
                    alert(
                        "Event Register Successfully.")
                        
                    this.setState({ mobileNo: "", email: "", firstName: "", lastName: "", assignRole: "",QRCodeValue:responseData.response.userId+
                "_"+responseData.response.eventId });
                } else if (!responseData.status && "auth" in responseData) {
                    AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });
    }

    onSelectLocation = (e) => {
        let location = e.target.value
       
        const foundItem = this.state.Locations.find(item => item.place_id == location);
        this.state.QRCodeValue=location;
        // console.log("LOC ",foundItem,this.getFormattedAddressComponentData(foundItem?.addressComponent || null) )
        this.setState({ selectedLocation: location, defaultAddress: this.getFormattedAddressComponentData(foundItem?.addressComponent || null), refreshInput: true })

        setTimeout(() => {
            this.setState({ refreshInput: false })
        }, 100)
        // setResult(foundItem || null);
    }
    getFormattedAddressComponentData = (AddressDetails) => {
        if (!AddressDetails) {
            // console.log("NULL FOUND")
            // this.setState({ city: "" })
            // this.setState({ district: "" })
            // this.setState({ state: "" })
            // this.setState({ country: "" })
            // this.setState({ street_address: "", defaultAddress : "", addressComponent : null, refreshInput : true });
            // setTimeout(() => {
            //     this.setState({ refreshInput : false })
            // },100)
            this.cleanAddress()
            // this.setAutocompleteValue("")
            return ""
        }
        console.log("\n details =====->", AddressDetails);
        let address_components = AddressDetails.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_3') {
                this.setState({ district: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
            if (address.types[0] == 'postal_code') {
                this.setState({ postal_code: address.long_name })
            }
        })

        let addressComponentObj = { address_components: AddressDetails.address_components, formatted_address: AddressDetails.formatted_address };

        console.log(JSON.stringify(addressComponentObj));

        this.setState({ street_address: AddressDetails.formatted_address, addressComponent: addressComponentObj });

        // this.setAutocompleteValue(AddressDetails.formatted_address)
        return AddressDetails.formatted_address
    }

    getAddressComponentData = (AddressDetails, isPlaceSelected, e = null) => {
        // e.preventDefault()
        if (!AddressDetails) {
            if (!isPlaceSelected) {
                if (e.target.value != this.state.street_address) {
                    this.cleanAddress()
                }
            }
            // console.log("IS PlACE ",AddressDetails,isPlaceSelected,e)
            return
        }

        // console.log("\n details =====->", AddressDetails);
        let address_components = AddressDetails.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_3') {
                this.setState({ district: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
            if (address.types[0] == 'postal_code') {
                this.setState({ postal_code: address.long_name })
            }
        })

        let addressComponentObj = { address_components: AddressDetails.address_components, formatted_address: AddressDetails.formatted_address };

        // console.log(JSON.stringify(addressComponentObj));

        this.setState({ street_address: AddressDetails.formatted_address, addressComponent: addressComponentObj });
    }
    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" style={{ backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "150px" }}>
                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title" style={{ fontSize: "23px" }}>Karsevak Abhivadan Samaroh</h3>

                                        </div>

                                        <div className="card-body row col-12" style={{ padding: "0px" }}>
                                            <div className="col-md-8 border-right-desktop">
                                                <br />
                                                <h3 className="title card-title text-center">Event Registration</h3>
                                                <div className='col-md-12'>
                                                <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className="form-group">
                                                                <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                                <div className='row'>
                                                                    <div className='col-md-2'>
                                                                        <input type="text" name="ccode" className="form-control form-control-solid" placeholder="+91" onChange={(event) => Functions.onChange(event, this)} value={this.state.ccode} />
                                                                        <span className="form-text text-danger">{this.state.errors?.ccode}</span>
                                                                    </div>
                                                                    <div className='col-md-10'>
                                                                        <input type="text" name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" onBlur={(event) => this.checkMobileNumberIsRegistrered(event)} onChange={(event) => this.handleNumberChange(event)} value={this.state.mobileNo} />
                                                                        <span className="form-text text-danger">{this.state.errors?.mobileNo}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label>First Name / નામ:</label>
                                                                <input type="text" name="firstName" disabled={!this.state.enableInput} className="form-control form-control-solid" placeholder="Enter first name" onChange={(event) => Functions.onChange(event, this)} value={this.state.firstName} />
                                                                <span className="form-text text-danger">{this.state.errors?.firstName}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Last Name / અટક:</label>
                                                                <input type="text" name="lastName" disabled={!this.state.enableInput} className="form-control form-control-solid" placeholder="Enter last name" onChange={(event) => Functions.onChange(event, this)} value={this.state.lastName} />
                                                                <span className="form-text text-danger">{this.state.errors?.lastName}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                            <label>City / શહેર</label>
                                                            {!this.state.refreshInput ? <Autocomplete disabled={!this.state.isNumberVerified} className="form-control form-control-solid py-4 px-8 h-auto"
                                                            disabled={!this.state.enableInput}
                                                                placeholder={this.state.defaultAddress == "" ? "Enter address" : this.state.defaultAddress}
                                                                apiKey="AIzaSyAeByXtQ4rJOq887gNcEAtAdXysiQgwVOk"
                                                                onPlaceSelected={(place) => this.getAddressComponentData(place, true)}
                                                                defaultValue={this.state.defaultAddress}
                                                                ref={this.googlePlaceInputRef}
                                                                onBlur={(e) => setTimeout(() => this.getAddressComponentData(null, false, e), 500)}
                                                                // inputAutocompleteValue={this.state.defaultAddress}
                                                                options={{
                                                                    // fields: ["address_component", "formatted_address"],
                                                                    types: ["(regions)"]
                                                                }}
                                                            /> :
                                                                <input type="text" disabled name="dummy" className="form-control form-control-solid py-4 px-8 h-auto pac-target-input" placeholder="Enter Address" />
                                                            }
                                                            {/* <GooglePlace /> */}
                                                            {/* <span className="form-text text-default" style={{ padding : 0, color : 'grey', float: 'right' }}>ઉદા. - શહેર, રાજ્ય, જિલ્લા, દેશ</span> */}
                                                            <span className="form-text text-danger" style={{ padding: 0, }}>{this.state.errors?.address}</span>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>

                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Remarks / નૉૅધ: </label>
                                                                <textarea type="text" name="remark" disabled={!this.state.enableInput} className="form-control form-control-solid" placeholder="Enter remark" onChange={(event) => Functions.onChange(event, this)} value={this.state.remark} ></textarea>
                                                                <span className="form-text text-danger">{this.state.errors?.remark}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <br />
                                                <label>Location:</label>

                                                <select  className="form-control form-select-solid" style={{ backgroundColor: '#F3F6F9' }} aria-label="Select Location" onChange={(event) => this.onSelectLocation(event)}>

                                                        {/* <option value="">Select Location</option> */}
                                                        {
                                                            this.state.Locations.map((location) => {
                                                                return (<option selected={this.state.selectedLocation == location.place_id} value={location.place_id}>{location.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                <span className="form-text text-danger">{this.state.errors?.LoactionName}</span>
                                                <h3 className="card-title text-center">Register QR</h3>
                                                <div className="text-center" >
                                                {this.state.QRCodeValue!="" &&
                                                    <QRCode 
                                                        // size={256}
                                                        //style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={this.state.QRCodeValue}
                                                    // viewBox={`0 0 256 256`}
                                                    />
                                                }
                                                 {this.state.QRCodeValue=="" &&
                                                   <span>No QR Code Found</span>
                                                }
                                                </div>
                                            </div>

                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mr-2"  disabled={this.state.IsRegister} onClick={(event) => this.onUserInvite(event)}>Register Now</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(RegisterEvent);
