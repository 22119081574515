import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Auth from "../Utilities/Auth";
import Constants from '../Utilities/Constants';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			User: '',
			firstChar: '',
			Organization: '',
			CouncilLogo: '',
			RoleName: "",
			mode: "",
			redirect: false
		};
	}

	_getUserData = async () => {

		let token = localStorage.getItem("token");

		let user = localStorage.getItem("user");
		let User = JSON.parse(user);

		let RoleName = localStorage.getItem("role");
		let mode = localStorage.getItem("mode");

		let Organization = localStorage.getItem("Organization");
		Organization = JSON.parse(Organization);
		// console.log(User);
		let CouncilLogo = Organization ? Organization.Logo : '';

		if (!User) {
			return Auth.Logout();
		} else {
			let firstChar = User?.UserDisplayName?.charAt(0);
			await this.setState({ token, User, RoleName, mode, firstChar, loading: false, Organization: Organization, CouncilLogo: CouncilLogo });
		}

	}

	async componentDidMount() {
		await this._getUserData();
	}

	async UNSAFE_componentWillReceiveProps(nextProps) {
		await this._getUserData();
	}

	componentWillUnmount() {
		localStorage.removeItem('heading');
	}

	render() {
		return (
			<>

				{/* <!--begin::Header--> */}
				<div id="kt_header" className="header header-fixed">
					{/* <!--begin::Container--> */}
					<div className="container-fluid d-flex align-items-stretch justify-content-between">
						{/* <!--begin::Header Menu Wrapper--> */}
						<div className="topbar">
							<h1 className="font-weight-bold" style={{ display: 'flex', color : '#bd2e32', alignItems: 'center',  }}>
								{/* <div className="mr-3">
									{
										(this.state.CouncilLogo !== '') ?
											<img alt="Pic" src={Constants.cdnURL + 'Organization/' + this.state.CouncilLogo} style={{ height: 30, objectFit: 'cover', borderRadius: 0 }} />
											:
											<img alt="Pic" src='assets/images/noImage.png' style={{ height: 30, objectFit: 'cover', borderRadius: 0 }} />
									}
								</div> */}
								VUF Admin</h1>
						</div>
						{/* <!--end::Header Menu Wrapper--> */}
						{/* <!--begin::Topbar--> */}
						<div className="topbar">
							{/* <!--begin::Notifications--> */}
							<div className="dropdown">
								{/* <!--begin::Toggle--> */}
								<div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
									<div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
										<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
										<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{this.state.User.UserDisplayName}</span>
										<span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
											<span className="symbol-label font-size-h5 font-weight-bold">{this.state.firstChar}</span>
										</span>
									</div>
								</div>
								{/* <!--end::Toggle--> */}
								{/* <!--begin::Dropdown--> */}
								<div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
									<div className="d-flex flex-column pl-5 pt-3 pb-3 rounded-top bg-primary">
										<h4 className="d-flex rounded-top pl-5 m-0">
											<span className="text-white">User Detail</span>
										</h4>
									</div>
									{/* <!--begin::Content--> */}
									<div style={{ padding: '10px 20px' }}>
										<div className="d-flex align-items-start">
											<div className="symbol symbol-100 mr-5 mt-3">
												<div className="symbol-label" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}></div>
												<i className="symbol-badge bg-success"></i>
											</div>
											<div className="d-flex flex-column" style={{ width: "100%" }}>
												<span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{this.state.User.UserDisplayName}</span>
												<div className="text-muted mt-1">{this.state.RoleName}</div>
												<div className="navi mt-2">
													<div className="navi-item">
														<span className="navi-link p-0 pb-2">
															<span className="navi-icon mr-1">
																<span className="fas fa-map-marked-alt text-primary">
																</span>
															</span>
															<span className="navi-text text-muted text-hover-primary">{this.state.Organization?.DisplayName}</span>
														</span>
													</div>
													<div className="navi-item">
														<span className="navi-link p-0 pb-2">
															<span className="navi-icon mr-1">
																<span className="fas fa-key text-primary">
																</span>
															</span>
															<Link to={'/ChangePassword'} className="navi-text text-muted text-hover-primary cursor-pointer">Change Password</Link>
														</span>
													</div>
													<button onClick={() => { Auth.Logout() }} className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</button>
												</div>
											</div>
										</div>
									</div>
									{/* <!--end::Content--> */}
								</div>
								{/* <!--end::Dropdown--> */}
							</div>
							{/* <!--end::Notifications--> */}

							{/* <!--begin::User--> */}

							{/* <!--end::User--> */}
						</div>
						{/* <!--end::Topbar--> */}
					</div>
					{/* <!--end::Container--> */}
				</div>
				{/* <!--end::Header--> */}
			</>
		);
	}
}

export default Header;