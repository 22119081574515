import React, { Component,useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import moment from 'moment';
// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'

class Event extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            assignRole: "",
            eventName: "",
            image: null,
            eventDescription: "",
            eventBannerImage: "",
            eventStartDate: "",
            eventEndDate: "",
            registrationStartDate: "",
            registrationEndDate: "",
            eventStartTime: "",
            eventEndTime: ""
        };
       
    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');


        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }






    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.eventName?.trim() === "") {
            formIsValid = false;
            errors.eventName = "Please enter event Name";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }


    onUserInvite = async (e) => {
        e.preventDefault()

        this.setState({ loading: true })

        if (!this.handleValidation()) {
            return false
        }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { eventName, eventDescription, eventBannerImage, eventStartDate, eventEndDate, registrationStartDate, registrationEndDate, eventStartTime,
            eventEndTime, token,image } = this.state;

        const formData = new FormData();
        formData.append('eventName',eventName);
        formData.append('eventDescription',eventDescription);
        // if(image != null) {
        //     // formData.append('eventBannerImage', image);
        //     formData.append('image', image);
        //   }
          if(eventBannerImage != null) {
            formData.append('eventBannerImage', image);
            // formData.append('eventBannerImage', eventBannerImage);
          }
          formData.append('eventStartDate',eventStartDate != '' ? moment(eventStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
          formData.append('eventEndDate',eventEndDate != '' ? moment(eventEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
          formData.append('registrationStartDate',registrationStartDate != '' ? moment(registrationStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
          formData.append('registrationEndDate',registrationEndDate != '' ? moment(registrationEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
          formData.append('eventStartTime',eventStartTime);
          formData.append('eventEndTime',eventEndTime);

        let urlPath = "event/create-event";

        // return
        // postFormData(Constants.VUF_APP_API + urlPath, formData, { headers: { credentials: 'include', Accept: "*/*" } }).then((responseData) => {
          console.log(formData)

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: formData,
                redirect: 'follow',
            }).then((response) => response.json())
                .then((responseData) => {
            console.log(responseData);
            if (responseData.status) {
                alert(
                    "User Successfully Added.")
                this.setState({
                    eventName: "", eventDescription: "", eventBannerImage: "", eventStartDate: "", eventEndDate: "", registrationStartDate: "",
                    registrationEndDate: "", eventStartTime: "", eventEndTime: "",image:""
                });
                
            } else if (!responseData.status && "auth" in responseData) {
                AuthController.Logout();
            } else if (!responseData.status && responseData.error) {
                alert(
                    responseData?.error || "Error")
                this.setState({ loading: false });
            } else {
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }
        }).catch(error => {
            console.log("err", error);
            alert(
                'Something went wrong, Please try again after sometime.'
            )
            this.setState({ loading: false });
        }).finally(final => {
            // return this.setState({ isInvitingUser: false })
        });
    }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        const fileTypes = ["JPG", "PNG"];
        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title" >Add Event</h3>

                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="eventName" className="form-control form-control-solid" placeholder="Enter event name" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventName} />
                                                            <span className="form-text text-danger">{this.state.errors?.eventName}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">

                                                            <label>Upload Banner</label><br />
                                                            <input type="file" name="image"
                                                                onChange={(event) => {
                                                                    this.setState({ image:event.target.files[0] })
                                                                }}
                                                                className="form-control form-control-file" data-buttonname="btn-secondary"
                                                            />

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Start Date</label><br />
                                                            {/* <input type="text" name="eventStartDate" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventStartDate} /> */}

                                                            <DatePicker className="form-control form-control-solid" name="eventStartDate"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.eventStartDate}
                                                                onChange={(e) => { this.setState({ eventStartDate: e }) }}
                                                                // onChange={(event) => Functions.onChange(event, this)} 
                                                                value={this.state.eventStartDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Event Start Time</label>

                                                            <input type="time" name="eventStartTime" className="form-control form-control-solid" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventStartTime} />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>End Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="eventEndDate"
                                                                filterDate={(d) => { return this.state.eventStartDate <= d }}
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.eventEndDate}
                                                                onChange={(e) => { this.setState({ eventEndDate: e }) }}
                                                                value={this.state.eventEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Event End Time</label>
                                                            <input type="time" name="eventEndTime" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventEndTime} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Registration Start Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="registrationStartDate"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.registrationStartDate}
                                                                onChange={(e) => { this.setState({ registrationStartDate: e }) }}
                                                                value={this.state.registrationStartDate}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Registration End Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="registrationEndDate"
                                                                filterDate={(d) => { return this.state.registrationStartDate <= d }}
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.registrationEndDate}
                                                                onChange={(e) => { this.setState({ registrationEndDate: e }) }} value={this.state.registrationEndDate}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Description</label>
                                                        <textarea type="text" name="eventDescription" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventDescription} ></textarea>
                                                        <span className="form-text text-danger">{this.state.errors?.eventDescription}</span>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onUserInvite(event)}>Register Now</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(Event);
