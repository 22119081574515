import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";

// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import { appendScript } from '../Utilities/appendScript';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
class SangathanCreate extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            ResErrors: {},
            RoleList: [],
            sangathanList: [],
            isRedirect: false,
            sangathanId: null,
            dropdownData : [],
            sangathanName: "",
            selectedSangathan : {
                label: null,
                name: null,
                _id : null,
                parentId : null,
                options: [],
            },
            currentEditItem: {},
            itemLoading: false,
            itemDeleteLoading: false,
            deleteItemId : null,
            moveItemSelected: {},
            isPrepareMove : false,
            isMoving : false,
            moveTargetItem: {},
        };
    }

    async componentDidMount() {

        await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        await appendScript('/assets/plugins/custom/datatables/datatables.bundle.js');
       
        

        let heading = {
            title: 'Sangathan',
            subTitle: ['List of Sangathans']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

        await this._getUserCred();

        // setTimeout(async () => {
        //     const script = document.createElement('script');
        //     script.async = true;
        //     script.text = `$(document).ready(function () {
        //         $('#kt_datatable1').DataTable({
        //             responsive: true,
        //             // Pagination settings
        //             dom: \`<'row'<'col-sm-6 text-left'f><'col-sm-6 text-right'B>>
        //             <'row'<'col-sm-12'tr>>
        //             <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>\`,
        //             buttons: [
        //             'print',
        //             // 'copyHtml5',
        //             'excelHtml5',
        //             'csvHtml5',
        //             'pdfHtml5',
        //             ]
        //         });
        //     })`;
        //     document.body.appendChild(script);
            
    
        // },500)

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    /**
     * _getUserCred()
     * @param null
     * @returns user details
     * @description This function get user detail from local storage
     */
    _getUserCred = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const User = user;

        const role = await localStorage.getItem('role');
        const token = await localStorage.getItem('token');

        
       
        // console.log("LOG ",user,token)
        if (!token) {
            return AuthController.Logout();
        }
        else {
            
            // await this.setState({ User, token });
            let state = user;
            // let { sangathanId } = this.state;
            let sangathanId = state?.sangathanId || "";
            // console.log("SANG: ", user);
            const Role = state.role || "Member";
            await this.setState({
              user: state,
              token: token,
              role: state.role,
              isMember: (!state.role || state.role?.toLowerCase() == "member" || false),
              sangathanId: sangathanId,
              loading: true
            });
            this.onGetSangathanList()
        }


        // const { state, signOut } = this.context;
       
    }


  onGetSangathanList = async (id = null,rootIndex = 0) => {
    // await this.setState({ loading: true })
    // const { state, signOut } = this.context;
    const { token, user, sangathanId } = this.state;

    let state = user
    // get-sangathan-list

    
    let urlPath = "sangathan/get-all-sangathan-list"

    // console.log("List Sangathan =>", Constants.url + urlPath)

    // console.log("TOKEN ",token)
    // return 

    fetch(Constants.url + urlPath, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
      // body: Body,
    }).then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.status && 'response' in responseData) {
            // console.log("SANGATHANS ",responseData?.response?.sangathanList)

            this.setState({ sangathanList : responseData?.response?.sangathanList || [] })
           
        } else if (!responseData.status && "auth" in responseData) {
            AuthController.Logout();
        } else if (!responseData.status && responseData.error) {
            console.log("Something went wrong")
            this.setState({ loading: false })
        } else {
            console.log("Something went wrong")
            this.setState({ loading: false })
        }
      }).catch(error => {
        console.log("err", error);
        
      }).finally(final => {
        return this.setState({ loading: false })
      });
  }


    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = (sangathan) => {
        let errors = {};
        let formIsValid = true;
        const stringRegex = /^[A-Za-z]{3,}$/;
        const charRegex = /.{3,}/;

        if (this.state.sangathanName?.trim() === "") {
            formIsValid = false;
            errors.sangathanName = "Please enter sangathan name";
        }

        if (!charRegex.test(this.state.sangathanName)) {
            formIsValid = false;
            // errors.sangathanName = "Please enter only string with minimum 3 characters";
            errors.sangathanName = "Please enter minimum 3 characters";
        }

        if (sangathan.name == this.state.sangathanName) {
            formIsValid = false;
            errors.sangathanName = "You have not made any changes";
        }

       

        this.setState({ errors: errors });

        return formIsValid;

        // if (!this.handleValidation()) {
        //     return false
        // }
    }


    onRenameSangathan = (e,sangathan) => {
        e.preventDefault()

        // const { signOut } = this.context;
        
        const { token, user, sangathanName, currentEditItem } = this.state;
        

        if (!this.handleValidation(sangathan)) {
            return false
        }
        this.setState({ loading : false, itemLoading : true })

        const Body = JSON.stringify({
          _id: currentEditItem?._id,
          name: sangathanName.trim()
        })
    
        let urlPath = "sangathan/rename-sangathan";
    
        // console.log("rename sangathannn =>", Constants.url + urlPath, token, Body)

        // this.setState({ loading: false });
        // return


        fetch(Constants.url + urlPath, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: Body,
        }).then((response) => response.json())
          .then(async (responseData) => {
            if (responseData.status && 'response' in responseData) {
              
                alert(
                    "Sangathan Renamed Successfully.")
                await this.setState({ itemLoading: false, sangathanName: "", currentEditItem : {}, sangathanList : responseData?.response?.sangathanList || [] })
                // this.redirectWithPath("/sangathan-create")
            } else if (!responseData.status && "auth" in responseData) {
                AuthController.Logout();
            } else if (!responseData.status && responseData.error) {
              alert(
                responseData?.error || "Error")
                this.setState({ itemLoading: false });
            } else {
              alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ itemLoading: false });
            }
          }).catch(error => {
            console.log("err", error);
            alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ itemLoading: false });
          }).finally(final => {
            return this.setState({ itemLoading: false })
          });
      }


      onCheckDeleteSangathan = (e,sangathan) => {
        e.preventDefault()

        // const { signOut } = this.context;
        
        const { token } = this.state;
        

        this.setState({ loading : true, itemDeleteLoading : true })

        const Body = JSON.stringify({
          _id: sangathan?._id
        })
    
        let urlPath = "sangathan/check-delete-sangathan";
    
        console.log("check delete sangathan =>", Constants.url + urlPath, token, Body)

        // this.setState({ loading: false });
        // return


        fetch(Constants.url + urlPath, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: Body,
        }).then((response) => response.json())
          .then(async (responseData) => {
            if (responseData.status && 'response' in responseData) {
              
                if(responseData.response.readyToDelete) {
                    this.onDeleteSangathan(e,sangathan)
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                      )
                      this.setState({ loading: false });
                }

                // this.redirectWithPath("/sangathan-create")
            } else if (!responseData.status && "auth" in responseData) {
                AuthController.Logout();
            } else if (!responseData.status && responseData.error && responseData.linkedChild) {

                if(responseData.linkedChild.length > 0) {
                    console.log("DATA ",responseData)
                    let list = ''
                    responseData.linkedChild.map((s,i) => {
                        list += "- "+s?.name+"\n" || '-'+'\n ';
                    })

                    if(responseData.type == "HAS_MEMBER") {
                        
                        alert(
                            `${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error")
                            this.setState({ loading: false });
                    } else {
                      
                        const confirmDelete = window.confirm(`${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error");
                        if (confirmDelete) {
                            // Delete the item or perform the desired action
                            console.log("Item deleted.");
                            this.onDeleteSangathan(e,sangathan)
                        } else {
                            console.log("Deletion canceled.");
                        }
                    }

                }

                this.setState({ loading: false })
            } else if (!responseData.status && responseData.error) {
              alert(
                responseData?.error || "Error")
                this.setState({ loading: false });
            } else {
              alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ loading: false });
            }
          }).catch(error => {
            console.log("err", error);
            alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ loading: false });
          }).finally(final => {
            return this.setState({ loading: false, deleteItemId : null, })
          });
      }


      // DIRECT API TO REMOVE SANGATHAN BUT DO NOT CALL BEFORE CHECK DELETE API
      onDeleteSangathan = (e,sangathan) => {
        e.preventDefault()

        // const { signOut } = this.context;
        
        const { token } = this.state;
        

        this.setState({ loading : true, itemDeleteLoading : true })

        const Body = JSON.stringify({
          _id: sangathan?._id
        })
    
        let urlPath = "sangathan/delete-sangathan";
    
        console.log("delete sangathannn =>", Constants.url + urlPath, token, Body)

        // this.setState({ loading: false });
        // return


        fetch(Constants.url + urlPath, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: Body,
        }).then((response) => response.json())
          .then(async (responseData) => {
            if (responseData.status && 'response' in responseData) {
              
                alert(
                    "Sangathan Removed Successfully.")
                await this.setState({ loading: false, sangathanName: "", currentEditItem : {}, sangathanList : responseData?.response?.sangathanList || [] })
                // this.redirectWithPath("/sangathan-create")
            } else if (!responseData.status && "auth" in responseData) {
                AuthController.Logout();
            } else if (!responseData.status && responseData.error && responseData.linkedChild) {

                if(responseData.linkedChild.length > 0) {
                    console.log("DATA ",responseData)
                    if(responseData.type == "HAS_MEMBER") {
                        alert(
                            responseData?.error || "Error")
                            this.setState({ loading: false });
                    } else {
                        let list = ''
                        responseData.linkedChild.map((s,i) => {
                            list += "- "+s?.name || '-'+'\n ';
                        })
    
                        const confirmDelete = window.confirm(`${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error");
                        if (confirmDelete) {
                            // Delete the item or perform the desired action
                            console.log("Item deleted.");
                        } else {
                            console.log("Deletion canceled.");
                        }
                    }

                }

                this.setState({ loading: false })
            } else if (!responseData.status && responseData.error) {
              alert(
                responseData?.error || "Error")
                this.setState({ loading: false });
            } else {
              alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ loading: false });
            }
          }).catch(error => {
            console.log("err", error);
            alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ loading: false });
          }).finally(final => {
            return this.setState({ loading: false, deleteItemId : null, })
          });
      }

    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    redirectWithPath(path) {
        // sangathan-create
        return <Navigate to={path} />
    }

    onCancelEdit = (sangathan) => {
        this.setState({ currentEditItem : {}, sangathanName : '', errors : {} })
    }
    onSetEdit = (sangathan) => {

        this.setState({ currentEditItem : sangathan, sangathanName : sangathan.name })
    }

    handleDeleteClick = (e,sangathan) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this sangathan?");
        if (confirmDelete) {
            // Delete the item or perform the desired action
            this.setState({ deleteItemId : sangathan._id, })
            this.onCheckDeleteSangathan(e,sangathan)
            // this.onDeleteSangathan(e,sangathan)
            console.log("Item deleted.");
        } else {
          console.log("Deletion canceled.");
        }
    };

    setMoveItem = (e,sangathan) => {
        this.setState({ moveItemSelected : sangathan, isPrepareMove : true, })
    }

    cancelMove = (e,sangathan) => {
        this.setState({ moveItemSelected : {}, isPrepareMove : false, })
    }

    confirmTarget = (e,sangathan) => {
      
        
        const confirmMove = window.confirm(`Move this ${this.state.moveItemSelected.name.toUpperCase()} to ${sangathan.name.toUpperCase()} with its users, You can not undo this action, Are you sure?`);
        if (confirmMove) {
            
            this.setTargetItem(e,sangathan)
            
        } else {
          console.log("move canceled.");
          this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {},   })
        }

    }

    setTargetItem = (e,sangathan) => {
        console.log(`Moving ${this.state.moveItemSelected.name} to ${sangathan.name}`)

        this.setState({ isMoving : true, moveTargetItem : sangathan })

        this.onMoveSangathan(sangathan)
        // setTimeout(() => {
        //     this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}  })
        // }, 3000)

    }

    onMoveSangathan = (targetSangathan) => {


        // const { signOut } = this.context;
        
        const { token, user, moveItemSelected } = this.state;
        

        if (!moveItemSelected?._id && !targetSangathan?._id) {
            return false
        }
        

        const Body = JSON.stringify({
          sourceId: moveItemSelected?._id,
          targetId: targetSangathan?._id
        })
    
        let urlPath = "sangathan/move-sangathan";
    
        console.log("rename sangathannn =>", Constants.url + urlPath, token, Body)

        // this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {},   })
        // return


        fetch(Constants.url + urlPath, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: Body,
        }).then((response) => response.json())
          .then(async (responseData) => {
            if (responseData.status && 'response' in responseData) {
                setTimeout(async () => {
                alert(
                    "Sangathan Moved Successfully.")
                await this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}, sangathanList : responseData?.response?.sangathanList || [] })
                }, 2000)
            } else if (!responseData.status && "auth" in responseData) {
                AuthController.Logout();
            } else if (!responseData.status && responseData.error) {
              alert(
                responseData?.error || "Error")
                await this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}, })
            } else {
              alert(
                'Something went wrong, Please try again after sometime.'
              )
              await this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}, })
            }
          }).catch(error => {
            console.log("err", error);
            alert(
                'Something went wrong, Please try again after sometime.'
              )
              this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {} })
          }).finally(final => {
            // return this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}  })
          });
      }

    render() {

        const { sangathanList, loading, currentEditItem, itemLoading, deleteItemId, sangathanName, errors, isPrepareMove, moveItemSelected, isMoving, moveTargetItem } = this.state;

        
        const columns = [
            {
                name: 'Sr No',
                width: "10%",
                sortable: true,
                selector: (row,index) => index+1
            },
            {
                name: 'Name',
                width: "45%",
                sortable: true,
                selector: row => currentEditItem?._id == row._id ? 
                    <>
                        <input type="text" name="sangathanName" className="form-control form-control" placeholder={row?.name || 'Sangathan name'} onChange={(event) => Functions.onChange(event, this)} value={sangathanName} />
                        <span className="form-text text-danger">{errors?.sangathanName}</span>
                    </> 
                    : 
                    <input type="text" name="s" className="form-control-plaintext form-control" style={{ outline : 'none', paddingLeft : '1rem', paddingRight : '1rem', border : 'none' }} value={row?.name || '-'} /> || '-'
            },
            {
                name: 'Users',
                width: "10%",
                sortable: true,
                selector: row => row.userCount
            },
            {
                name: 'Action',
                width : '45%',
                selector: row => row.action,
                sortable: true,
                cell: (data) => <>
                {
                        data._id == deleteItemId ?
                        <button type="button" className="btn btn-danger mr-2" onClick={(event) => false}>Deleting...</button>
                        :
                        <button type="button" className="btn btn-danger mr-2" onClick={(event) => { this.handleDeleteClick(event,data)  }}>Delete</button>
                }
                {
                    !isPrepareMove ? 
                        <button type="button" className="btn btn-warning mr-2" onClick={(event) => { this.setMoveItem(event,data)  }}>Move</button>
                        :
                            data._id == moveItemSelected?._id ?
                            isMoving ? 
                                <button type="button" className="btn btn-warning mr-2" style={{ display : 'flex', alignItems : 'baseline', justifyContent : 'center' }}  onClick={(event) => false}>
                                    <span className="svg-icon menu-icon fas fa-arrow-left" style={{ marginTop : 5}} /> 
                                    {/* Moving */}
                                    <Dots />
                                </button>
                                :
                                <button type="button" className="btn btn-default mr-2" onClick={(event) => this.cancelMove(event,data)}>Cancel Move</button>
                            :
                            isMoving ? 
                                data._id == moveTargetItem._id ?
                                <button type="button" className="btn btn-default mr-2" style={{ display : 'flex', alignItems : 'baseline', justifyContent : 'center' }} onClick={(event) => false}>
                                    {/* <Dots /> */}
                                    <span className="svg-icon menu-icon fas fa-arrow-right" style={{ marginTop : 5}} />
                                    {/* Moving Here */}
                                    <Dots />
                                </button>
                                :
                                <button type="button" className="btn btn-light mr-2" onClick={(event) => false}><span className="svg-icon menu-icon fas fa-ban" />Wait</button>
                        :
                        <button type="button" className="btn btn-warning mr-2" onClick={(event) => { this.confirmTarget(event,data)  }}>Move To Here</button>
                }
                { currentEditItem?._id == data._id ? 
                    <>
                        {
                            itemLoading ?
                            <button type="button" className="btn btn-success mr-2"><Spinner color={'#fff'} />Renaming...</button>
                            :
                            <button type="button" className="btn btn-success mr-2" onClick={(event) => { this.onRenameSangathan(event,data) }}>Save</button>}
                        <button type="button" className="btn btn-default mr-2" onClick={(event) => { this.onCancelEdit() }}>Cancel</button>
                    </> 
                    : 
                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => { this.onSetEdit(data) }}>Rename</button>
                }
                </>
            },
        ];

        const tableData = {
            columns,
            data: sangathanList,
        };

        return (
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title"> List of Sagnathans</h3>
                                       
                                        </div>
                                        <div className="card-body row">

                                            <div className='col-md-12'>
                                                    <DataTableExtensions
                                                        {...tableData}
                                                        export={false}
                                                        print={false}
                                                    >
                                                        <DataTable
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            highlightOnHover
                                                            paginationPerPage={30}
                                                        />
                                                    </DataTableExtensions>
                                                    {/* <table className="table table-head-custom" id="kt_datatable1">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr No</th>
                                                            <th>Sangathan Name</th>
                                                            <th>Users</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            
                                                                sangathanList.map((sangathan, index) => {
                                                                return (<tr>
                                                                    <td style={{ width: '10%',  textAlign : 'center' }}>{index+1}</td>
                                                                    <td style={{ width: '50%' }}>{ currentEditItem?._id == sangathan._id ? 
                                                                    <>
                                                                        <input type="text" name="sangathanName" className="form-control form-control" placeholder={sangathan?.name || 'Sangathan name'} onChange={(event) => Functions.onChange(event, this)} value={this.state.sangathanName} />
                                                                        <span className="form-text text-danger">{this.state.errors?.sangathanName}</span>
                                                                    </> 
                                                                    : 
                                                                    <input type="text" name="s" className="form-control-plaintext form-control" style={{ outline : 'none', paddingLeft : '1rem', paddingRight : '1rem', border : 'none' }} value={sangathan?.name || '-'} /> || '-'}</td>
                                                                    <td style={{ width: '10%', textAlign : 'center'}}>
                                                                        {sangathan?.userCount || '0'}
                                                                    </td>
                                                                    <td style={{ width: '25%' }}>
                                                                        {
                                                                                sangathan._id == deleteItemId ?
                                                                                <button type="button" className="btn btn-danger mr-2" onClick={(event) => false}>Deleting...</button>
                                                                                :
                                                                                <button type="button" className="btn btn-danger mr-2" onClick={(event) => { this.handleDeleteClick(event,sangathan)  }}>Delete</button>
                                                                        }
                                                                        { currentEditItem?._id == sangathan._id ? 
                                                                            <>
                                                                                {
                                                                                    itemLoading ?
                                                                                    <button type="button" className="btn btn-success mr-2"><Spinner color={'#fff'} />Renaming...</button>
                                                                                    :
                                                                                    <button type="button" className="btn btn-success mr-2" onClick={(event) => { this.onRenameSangathan(event,sangathan) }}>Save</button>}
                                                                                <button type="button" className="btn btn-default mr-2" onClick={(event) => { this.onCancelEdit() }}>Cancel</button>
                                                                            </> 
                                                                            : 
                                                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => { this.onSetEdit(sangathan) }}>Rename</button>
                                                                        }
                                                                        
                                                                    </td>
                                                                </tr>)
                                                                })
                                                        }
                                                    </tbody>
                                            </table> */}
                                            </div>
                                        </div>
                                        {/* <div className="card-footer">
                                            {
                                                this.state.btnLoading ?
                                                    <button type="button" className="btn btn-primary mr-2"><Spinner color={'#fff'} /></button>
                                                    :
                                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onAddSangathan(event)}>Submit</button>
                                            }

                                           
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(SangathanCreate);
