import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import axios from 'axios';
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import { appendScript } from '../Utilities/appendScript';
import {
    GooglePlayButton,
    AppGalleryButton,
    ButtonsContainer,
    AppStoreButton
  } from "react-mobile-app-button";

import QRCode from "react-qr-code";
import ReCAPTCHA from "react-google-recaptcha";

import withRouter from '../Utilities/withRouter'

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

const BootstrapInput = styled(TextField)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

class EventRegisterView extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.recaptchaRef = React.createRef();

        const { userId } = props.params;
        // const location = match.params.location;

        let userIdParam = ""
        // let enableLocationChange = false
        let showQR = false

        if(userId) {
            userIdParam = userId
            showQR = true
        }
        console.log("PARAMS LOCATION ",userIdParam,showQR)

        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            country_code: '+91',
            ccode: "+91",
            mobileNo: "",
            currentUserId: userIdParam,
            showQR: showQR,
            firstName: "",
            lastName: "",
            token : 'Bearer SECRET'
        };
    }

    async componentDidMount() {

        await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

       

        await this._getUserCred();

    }



    /**
     * _getUserCred()
     * @param null
     * @returns user details
     * @description This function get user detail from local storage
     */
    _getUserCred = async () => {
        
        
        await this.onUserInvite();

        // const { state, signOut } = this.context;
        
    }


  onUserInvite = async (e) => {
       
          
            this.setState({ loading : true })
            // const { state, signOut } = this.context;
            // await this.setState({ isInvitingUser: true })
            const { navigation } = this.props;
            const { currentUserId } = this.state;
            const Body = JSON.stringify({
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
            })

            let urlPath = "user/event-user/"+currentUserId;
            
            
            fetch(Constants.AUTH_URL + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);

                if (responseData.status && 'users' in responseData) {

                    let userInfo = responseData.users
                
                    this.setState({ mobileNo: userInfo?.mobileNo, firstName: userInfo?.firstName || "", lastName: userInfo?.lastName || "", loading : false });
                    // alert(
                    //     'Registered! \n\n  Visitor entry registered successfully.',
                    // )
                    
    
                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                    alert(
                        'Sorry! \n\n '+responseData.error)
                    this.setState({ loading : false, showQR : false })
                } else if(typeof responseData.error === "object") {
                    alert(
                    'Error!  \n\n '+ responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading : false, showQR : false })
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading : false, showQR : false })
                }

            }).catch(error => {
                // // console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                  )
                  this.setState({ loading: false, showQR : false });
            }).finally(final => {
                // this.recaptchaRef.current.reset();
                // return this.setState({ isInvitingUser: false })
            });
    }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        var path = window.location.origin

        // // // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container" style={{ marginTop : 30 }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header row" style={{ textAlign : 'center', alignItems : 'center', justifyContent : 'space-between'}}>
                                            <div className='col-md-12' style={{ display : 'flex', justifyContent : 'center'}}>
                                                <img style={{ height : 100, width : 100 }} src={'/assets/UmiyaFoundationWhite.png'} />
                                            </div>
                                           
                                        </div>
                                        <div className="card-body row" style={{ paddingTop : 10}}>

                                        

                                            <div className='col-md-12 border-right-desktop-deprecated'>
                                           
                                            <div className='col-md-12' style={{ display : 'flex', justifyContent : 'flex-start'}}>
                                                <label style={{ textAlign : 'center', width : '100%', fontSize : 23, fontWeight : '700', color : '#bc2f30', marginBottom : 10 }}>VUF Event Registration</label>
                                            </div>

                                              
                                                {/* <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Location:</label>
                                                        
                                                            <select disabled={this.state.enableLocationChange} className="form-control form-select-solid" style={{ backgroundColor : '#F3F6F9', color :  this.state.enableLocationChange ? '#b7b7c4' : '#3F4254' }} aria-label="Select Location" onChange={(e) => { this.setState({ selectedLocation : e.target.value }) }}>
                                                                
                                                                {
                                                                    this.state.Locations.map((location) => {
                                                                        return(<option selected={this.state.selectedLocation == location.place_id} value={location.place_id}>{location.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="form-text text-danger">{this.state.errors?.selectedLocation}</span>
                                                        </div>
                                                    </div>

                                                </div> */}

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>First Name / નામ:</label>
                                                            <input type="text" name="firstName" className="form-control form-control-solid" placeholder="Enter first name" disabled value={this.state.firstName} />
                                                            <span className="form-text text-danger">{this.state.errors?.firstName}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Last Name / અટક: </label>
                                                            <input type="text" name="lastName" className="form-control form-control-solid" placeholder="Enter last name" disabled value={this.state.lastName} />
                                                            <span className="form-text text-danger">{this.state.errors?.lastName}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                            <div className='row'>
                                                                <div className='col-md-2 col-lg-1 col-sm-2 col-xs-2' style={{ paddingRight : 0, width : '20%' }}>
                                                                      <input type="text" name="ccode" className="form-control form-control-solid" placeholder="+91" disabled value={this.state.ccode} />
                                                                      <span className="form-text text-danger">{this.state.errors?.ccode }</span>
                                                                </div>
                                                                <div className='col-md-10 col-lg-11 col-sm-10 col-xs-10' style={{ width : '80%' }}>
                                                                      <input type="text" name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" disabled value={this.state.mobileNo} />
                                                                      <span className="form-text text-danger">{this.state.errors?.mobileNo }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    

                                                </div>


                                                <div className='col-md-12'>

                                        
                                                    <div className="col-md-12"  style={{ marginTop : 10}}>
                                                            {this.state.showQR && <label style={{ textAlign : 'center', width : '100%', fontSize : 22, fontWeight : '600' }}>Show QR for Entry</label>}
                                                            { this.state.showQR && 
                                                                <div className="row border-bottom" style={{ justifyContent : 'center', marginBottom : 0 }}>
                                                                    <div className="col-md-4" style={{ marginTop : 10}}>
                                                                            <div className='qr-full' style={{ height: "auto", margin: "0 auto", maxWidth: '100%', width: "100%" }}>
                                                                                    {/* <label style={{ textAlign : 'center', width : '100%', fontSize : 14, fontWeight : '500' }}>Register QR</label> */}
                                                                                    <QRCode
                                                                                        size={100}
                                                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                                        // value={`${path}/${this.state.currentUserId}`}
                                                                                        value={`${this.state.currentUserId}`}
                                                                                        viewBox={`0 0 156 156`}
                                                                                    />
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            
                                                    </div>


                                                    </div>
                                               


                                            </div>


                                          
                                         
                                        </div>
                                   

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(EventRegisterView);
