import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";

// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import { appendScript } from '../Utilities/appendScript';

// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            ResErrors: {},
            OrganizationID: Constants.defaultOrganizationId,
            RoleList: [],
            SangathanList: [],
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            dropdownData : [],
            ccode: "+91",
            email: "",
            mobileNo: "",
            assignRole: "",
            firstName: "",
            lastName: "",
            selectedSangathan : {
                label: null,
                name: null,
                _id : null,
                options: [],
            }
        };
    }

    async componentDidMount() {

        await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

        let heading = {
            title: 'User',
            subTitle: ['Add User']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

        await this._getUserCred();


        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    /**
     * _getUserCred()
     * @param null
     * @returns user details
     * @description This function get user detail from local storage
     */
    _getUserCred = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const User = user;

        const role = await localStorage.getItem('role');
        const token = await localStorage.getItem('token');

        
       
        // console.log("LOG ",user,token)
        if (!token) {
            return AuthController.Logout();
        }
        else {
            
            // await this.setState({ User, token });
            let state = user;
            // let { sangathanId } = this.state;
            let sangathanId = state?.sangathanId || "";
            // console.log("SANG: ", user);
            const Role = state.role || "Member";
            await this.setState({
                user: state,
                token: token,
                role: state.role,
                isMember: (!state.role || state.role?.toLowerCase() == "member" || false),
                sangathanId: sangathanId,
                loading: true,
                RoleList: [],
                SangathanList: [],
                dropdownData : [],
                selectedSangathan : {
                    label: null,
                    name: null,
                    _id : null,
                    options: [],
                }
            });
            await this.onGetRoleList();
        }


        // const { state, signOut } = this.context;
       
    }

    
  onGetRoleList = async () => {
    await this.setState({ isLoading: true })
    // const { state, signOut } = this.context;
    const { token, user, searchText } = this.state;

    let state = user
    let urlPath = "role/get-roles"

    console.log("List Role =>", Constants.url + urlPath)

    // console.log("TOKEN ",token)
    // return 

    fetch(Constants.url + urlPath, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
      // body: Body,
    }).then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.status && 'response' in responseData) {
          const { roleList } = responseData.response;
          let RoleList = [];
          if (roleList) {
            Promise.all(
              roleList.map((roleData) => {
                if (state?.user?.sangathanId == this.state.sangathanId && state?.user?.role == roleData.name) return true;

                RoleList.push({ ...roleData, value: roleData.name })

              })
            )
          }
        //   console.log("ROLES ",RoleList)
          await this.setState({ RoleList, loading: true })
          this.onGetSangathanList()
        } else if (!responseData.status && "auth" in responseData) {
            AuthController.Logout();
        } else if (!responseData.status && responseData.error) {
            console.log("Something went wrong")
            this.setState({ loading: false })
        } else {
            console.log("Something went wrong")
            this.setState({ loading: false })
        }
      }).catch(error => {
        console.log("err", error);
        
      }).finally(final => {
        return this.setState({ loading: false })
      });
  }


  onGetSangathanList = async (id = null,rootIndex = 0) => {
    // await this.setState({ loading: true })
    // const { state, signOut } = this.context;
    const { token, user, sangathanId } = this.state;

    let state = user
    // get-sangathan-list

    // console.log("ID ",id)
    let baseSangathan = id
    if(id == null) {
        baseSangathan = sangathanId
    }

    let urlPath = "sangathan/get-sangathan-list?sangathanId=" + baseSangathan

    // console.log("List Sangathan =>", Constants.url + urlPath)

    // console.log("TOKEN ",token)
    // return 

    fetch(Constants.url + urlPath, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
      // body: Body,
    }).then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.status && 'response' in responseData) {
            // console.log("SANGATHANS ",responseData.response)

            // let child = responseData?.response?.childSangathanList.map((sangathan) => {
            //     return { name : sangathan.name, id : sangathan._id}
            // })
            // let dData = this.state.dropdownData
            let dData = this.state.dropdownData.slice(0, rootIndex+1);

            this.setState(prevState => ({ 
                selectedSangathan : {
                    label: responseData.response.sangathan.name,
                    name: responseData.response.sangathan.name,
                    _id : responseData.response.sangathan._id,
                    options: responseData?.response?.childSangathanList || [],
                }, 
                dropdownData: [
                ...dData,
                {
                  label: responseData.response.sangathan.name+" > ",
                  name: responseData.response.sangathan.name,
                  _id : responseData.response.sangathan._id,
                  options: responseData?.response?.childSangathanList || [],
                },
              ],
              loading: false 
            }))
           
        } else if (!responseData.status && "auth" in responseData) {
            AuthController.Logout();
        } else if (!responseData.status && responseData.error) {
            console.log("Something went wrong")
            this.setState({ loading: false })
        } else {
            console.log("Something went wrong")
            this.setState({ loading: false })
        }
      }).catch(error => {
        console.log("err", error);
        
      }).finally(final => {
        return this.setState({ loading: false })
      });
  }

  addDropdown = (sangathan,rootIndex,parentData) => {
    
    if(sangathan != "null") {
    
        sangathan = JSON.parse(sangathan)
        this.setState({ selectedSangathan: sangathan })
    
        this.onGetSangathanList(sangathan._id,rootIndex)
    } else {
        let dData = this.state.dropdownData.slice(0, rootIndex+1);
        // console.log("SELECTED ",JSON.parse(parentData))
        sangathan =  JSON.parse(parentData) 
        this.setState({ dropdownData : dData, selectedSangathan : sangathan })
    }

          
  }


    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        }

        if (this.state.lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        }

        if (this.state.assignRole?.trim() === "") {
            formIsValid = false;
            errors.assignRole = "Please assign role";
        }

        if (this.state.ccode?.trim() === "") {
            formIsValid = false;
            errors.ccode = "Please enter country code";
        }

        // if (this.state.email?.trim() === "") {
        //     formIsValid = false;
        //     errors.email = "Please enter email address";
        // }

        if (this.state.mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }

        if (this.state.selectedSangathan._id === null) {
            formIsValid = false;
            alert(
                'Sorry',
                "Please select any sangathan.",
               )
        }

       

        this.setState({ errors: errors });

        return formIsValid;
    }


  onUserInvite = async (e) => {
            e.preventDefault()

            

            if (!this.handleValidation()) {
                console.log("FAILED")
                return false
            }
            this.setState({ loading : true })
            // const { state, signOut } = this.context;
            // await this.setState({ isInvitingUser: true })
            const { navigation } = this.props;
            const { email, ccode, mobileNo, assignRole, firstName, lastName, token, selectedSangathan } = this.state;
            const Body = JSON.stringify({
                mobileNo: mobileNo,
                // countryCode: "+" + ccode,
                firstName: firstName,
                lastName: lastName,
                email: email,
                sangathanId: selectedSangathan._id,
                role: assignRole
            });

            let urlPath = "invitation/accept-invitation-direct";
            
            console.log(Constants.url + urlPath, token, Body)
            // return
            
            fetch(Constants.url + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                if (responseData.status && responseData.response && "user" in responseData.response) {
                    alert(
                        "User Successfully Added.")
                     this.setState({ mobileNo: "", email: "", firstName: "", lastName: "", assignRole: "", loading : false });
                     this._getUserCred()
                } else if (!responseData.status && "auth" in responseData) {
                    AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                        this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                      )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                  )
                  this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });
        }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title"> Add User</h3>
                                       
                                        </div>
                                        <div className="card-body row">

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>First Name:</label>
                                                            <input type="text" name="firstName" className="form-control form-control-solid" placeholder="Enter first name" onChange={(event) => Functions.onChange(event, this)} value={this.state.firstName} />
                                                            <span className="form-text text-danger">{this.state.errors?.firstName}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Last Name: </label>
                                                            <input type="text" name="lastName" className="form-control form-control-solid" placeholder="Enter last name" onChange={(event) => Functions.onChange(event, this)} value={this.state.lastName} />
                                                            <span className="form-text text-danger">{this.state.errors?.lastName}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Mobile Number:</label>
                                                            <div className='row'>
                                                                <div className='col-md-2'>
                                                                      <input type="text" name="ccode" className="form-control form-control-solid" placeholder="+91" onChange={(event) => Functions.onChange(event, this)} value={this.state.ccode} />
                                                                      <span className="form-text text-danger">{this.state.errors?.ccode }</span>
                                                                </div>
                                                                <div className='col-md-10'>
                                                                      <input type="text" name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" onChange={(event) => Functions.onChange(event, this)} value={this.state.mobileNo} />
                                                                      <span className="form-text text-danger">{this.state.errors?.mobileNo }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email: </label>
                                                            <input type="text" name="email" className="form-control form-control-solid" placeholder="Enter email" onChange={(event) => Functions.onChange(event, this)} value={this.state.email} />
                                                            <span className="form-text text-danger">{this.state.errors?.email}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                               
                                               
                                              
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Role:</label>
                                                           
                                                            <select className="form-control form-select-solid" style={{ backgroundColor : '#F3F6F9', color : '#3F4254' }} aria-label="Select role" onChange={(e) => { this.setState({ assignRole : e.target.value }) }}>
                                                                
                                                                <option value="">Select Role</option>
                                                                {
                                                                    this.state.RoleList.map((role) => {
                                                                        return(<option value={role.name}>{role.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="form-text text-danger">{this.state.errors?.assignRole}</span>
                                                        </div>
                                                    </div>

                                                </div>


                                                {dropdownData.map((dropdown, index) => (
                                                     <div key={index} className='row'>
                                                     <div className='col-md-12'>
                                                         <div className="form-group">
                                                             <label>{dropdown.label}</label>
                                                            
                                                             <select className="form-control form-select-solid" style={{ backgroundColor : '#F3F6F9', color : '#3F4254' }} aria-label={dropdown.label} onChange={(e) => { this.addDropdown(e.target.value,index,JSON.stringify(dropdown)) }}>
                                                                 
                                                                 <option value={"null"}>Select Sangathan</option>
                                                                 {
                                                                     dropdown.options.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={JSON.stringify(option)}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))
                                                                 }
                                                             </select>
                                                         </div>
                                                     </div>
 
                                                 </div>
                                                ))}

                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <h5>Selected Sangathan: </h5>
                                                            <h2>{this.state.selectedSangathan?.name || "Not selected" }</h2>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            {
                                                this.state.btnLoading ?
                                                    <button type="button" className="btn btn-primary mr-2"><Spinner color={'#fff'} /></button>
                                                    :
                                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onUserInvite(event)}>Submit</button>
                                            }

                                            {/* <Link to={'/ListBranch/' + this.state.OrganizationID} className="btn btn-secondary">Cancel</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(Dashboard);
