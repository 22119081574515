import React, { Component, Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// const Base = lazy(() => import('./Routes/base'));
import Base from './Routes/base';
import RegisterEvent from './Pages/RegisterEvent';
import EventAttend from './Pages/EventAttend';
import Event from './Pages/Event';
import EventDashboard from './Pages/EventDashboard';

import RegisterVisitor from './Pages/RegisterVisitor'
import EventRegisterView from './Pages/EventRegisterView'
import QrScan from './Pages/QrScan'

const Loginroute = lazy(() => import('./Routes/loginRoute'));

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: false,
      loading: true,
    }
  }

  async componentDidMount() {
    let user = await localStorage.getItem('user');
    user = await JSON.parse(user)
    if (user) {
      await this.setState({ isLogin: true })
    }
    console.log("App",this.state.isLogin)
  }


  render() {

    return (
      <Router forceRefresh={true}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
          {
            this.state.isLogin ?
              <Route path="/*" name='App' element={<Base />}></Route>
              :
              <>
                <Route path="/" name='App' exact element={<Loginroute />}></Route>

                <Route path="/register/" name='App' exact element={<RegisterVisitor />}></Route>
                <Route path="/register/:location" name='App' exact element={<RegisterVisitor />}></Route>
                <Route path="/event-registration/:userId" name='App' exact element={<EventRegisterView />}></Route>
                <Route path="/qr-scan" name='App' exact element={<QrScan />}></Route>

                <Route path="/EventRegister/:eventid" name="RegisterEvent" element={<RegisterEvent />}></Route>
                <Route path="/dashboard" name="Dashboard" element={<EventDashboard />}></Route>
                <Route path="/EventAttendant" name="EventAttend" element={<EventAttend />}></Route>
                <Route path="/Event" name="Event" element={<Event />}></Route>
                {/* <Route path="/signup" name='Signup' element={<Signup />}></Route> */}
              </>
          }
          </Routes>
        </Suspense>
      </Router>
    );

  }

}

export default App;
