/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import Functions from './Functions';

export default {

    TIP_MODE: "TIP",
    BEACH_MODE: "BEACHCHECK",
    TIP: {
        ID: 17,
        Name: "TIP",
        source: Functions.onSetCategoryImages("TIP")
    },
    ORGANIZATION_ID: 118,
    PARKING_ID: 5,

    url: process.env.REACT_APP_API_URL,
    urlShort: process.env.REACT_APP_API_SHORT_URL,
    cdnURL: process.env.REACT_APP_API_CDN_URL,
    VUF_APP_API:"https://api.app.vishvumiyafoundation.org/api/",
    AUTH_URL:"https://api.login.vishvumiyafoundation.org/api/",
    AUTH_APP_NAME:"VUFAPP",
    AUTH_SECRET:"fw!@y84oua$o3",

    // ImageUrl: process.env.REACT_APP_API_IMAGE_URL,

    THIRD_PARTY_TOKEN: "iWHHklBl5bYhCGlqVLZULGeJRIxj2XVrkuX2kY8zJa7VMqhEcbbPLA8qZnpFaQaRHmk",
    THIRD_PARTY_API_URL: 'https://www.universal-tutorial.com/api/',

    grant_type: process.env.REACT_APP_GRANT_TYPE,
    ClientId: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    scope: process.env.REACT_APP_SCOPE,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,

    standard_messages:
        [
            { key: "lifeguarded", name: "Lifeguarded", options: ["Yes", "No"] },
            { key: "public_toilet", name: "Public Toilets", options: ["Yes", "No"] },
            { key: "dogs_may_exercise", name: "Dogs may exercise", options: ["Permitted", "Not permitted", "Permitted in the designated area only", "Permitted at certain times only"] },
            { key: "cycling", name: "Cycling", options: ["Permitted", "Not permitted 10am-6pm"] },
            { key: "bbqs", name: "BBQs", options: ["Permitted", "Not permitted", "Not permitted until after 6pm", "Permitted in the designated area only", "Permitted at certain times only"] }
        ],
    categories: [
        { ID: 19, Name: 'CCTV', type: "CCTV" },
        // { ID: 20, Name: 'Open Shop' },
        // { ID: 21, Name: 'Guardians' },
        { ID: 22, Name: 'Safe Heavens', type: "SAFEHEAVENS" },
        // { ID: 23, Name: 'People' },
        { ID: 24, Name: 'Street Light', type: "STREETLIGHT" },
    ],
    defaultOrganizationId: 118
}
