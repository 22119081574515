import React, { Component } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import SubHeader from '../Components/SubHeader';
import Header from '../Include/Header';
import MobileHeader from '../Include/MobileHeader';
import SideBar from '../Include/SideBar';
import Dashboard from '../Pages/Dashboard';
import Constants from '../Utilities/Constants';
import { appendScript } from '../Utilities/appendScript';
import SangathanCreate from '../Pages/SangathanCreate';
import SangathanList from '../Pages/SangathanList';
export default class Base extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			user: {},
			role: "",
			mode: "",
			token: null,
			notice: "",
			editModal: false,
			LastModifiedDate: new Date(),
			OrganizationID: ""
		}
	}

	async componentDidMount() {

		// await appendScript('/assets/plugins/global/plugins.bundle.js')
		await appendScript('/assets/js/scripts.bundle.js');
		await this.getUserCred();
	}

	getUserCred = async () => {
		let user = await localStorage.getItem('user');
		// let role = await localStorage.getItem('role');
		let role = "Super Admin";
		let mode = await localStorage.getItem('mode');
		let token = await localStorage.getItem('token');
		user = JSON.parse(user);

		let OrganizationID = user.OrganizationID

		if (!token) {
			this.setState({ loading: false });
			// Actions.Auth({ logout: true });
		} else {
			await this.setState({ user, token, mode, role, OrganizationID });
			if (this.state.OrganizationID) {
				// await this._getOrganization();
			}
			// await this.getFlagStatus();
		}
	}

	// _getOrganization = async (UserToken = null, OrganizationID = null) => {
	// 	const { token } = this.state;
	// 	OrganizationID = this.state.OrganizationID;
	// 	let urlPath = "Organization/Get/" + OrganizationID;

	// 	fetch(Constants.url + urlPath,
	// 		{
	// 			method: 'GET',
	// 			headers: {
	// 				'Accept': 'application/json',
	// 				'Content-Type': 'application/json',
	// 				'Authorization': token
	// 			}
	// 		})
	// 		.then((response) => {
	// 			if (response.status == 401) {

	// 			} else {
	// 				response.json().then(async (responseData) => {
	// 					return await localStorage.setItem('Organization', JSON.stringify(responseData));
	// 				});
	// 			}
	// 		})
	// 		.catch(err => {
	// 			console.log("error =>", err);
	// 		});

	// }

	// getFlagStatus() {
	// 	fetch(Constants.url + "setting", {
	// 		method: 'GET',
	// 		headers: {
	// 			'Accept': 'application/json',
	// 			'Content-Type': 'application/json',
	// 			'Authorization': this.state.token
	// 		},
	// 	}).then((response) => {
	// 		if (response.status != 401) {
	// 			return response.json()
	// 		}
	// 	}).then((responseData) => {
	// 		if ("LastModifiedDate" in responseData) {
	// 			this.setState({ LastModifiedDate: responseData.LastModifiedDate })
	// 		}
	// 	}).catch(err => {
	// 		console.log("Map Flag=>", err)
	// 	})
	// }


	render() {
		if (this.state.ischeckAuth) {
			return (<div>Loading...</div>);
		} else {
			return (
				// <BrowserRouter>
				<>
					<MobileHeader />
					<div className="d-flex flex-column flex-root">
						<div className="d-flex flex-row flex-column-fluid page">
							<SideBar />
							{/* <!--begin::Wrapper--> */}
							<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
								<Header />
								<SubHeader />
								{/* <!--begin::Content--> */}
								<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
									<Routes>
										<Route path="/" exact name="home" element={<Dashboard />}></Route>
										<Route path="/sangathan-create" exact name="sangathan" element={<SangathanCreate />}></Route>
										<Route path="/sangathan-list" exact name="sangathan" element={<SangathanList />}></Route>
										{/* <Route path='/ListBranch/:id' name='ListBranch' element={<ListBranch />}></Route> */}

									</Routes>
								</div>
							</div>
						</div>
					</div>
				</>
				//</BrowserRouter>
			);
		}
	}
}
